@tailwind base;
@tailwind components;
@tailwind utilities;

:root{
  --themColor:#EEE4B1;
  --secondaryColor:#430A5D;
  --darkColor:#8C6A5D;
}

body {
  margin: 0;
  font-family: "Tajawal", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



.active{
  color:#6466f1;
}



.primary-bg-color{
  background-color: var(--themColor)!important;
}
.primary-color{
  color: var(--themColor) !important;
}
.secondary-bg-color{
  background-color: var(--secondaryColor) !important;
}
.secondary-color{
  color: var(--secondaryColor) !important;
}
.menu-border-color{
  border-color: var(--darkColor);
}
/* Butons */
.primary-btn{
  border-width: 1px ;
  border-color: var(--themColor);
  color: var(--themColor);
}

.header-space {
  /* height: 215px; */
  display: none;  
}
.footer-space {
  height: 80px;
  display: none;  
}

.header, .footer {
  display: none; /* Hide by default in regular view */
}
 
@page {
  margin: 20px;
}
@media print {
  .header, .footer {
    display: none;  
  }

  .header-space, .footer-space {
    display: none;
  }
  
  .page-break {
    page-break-before: always;
  }
  
  /* منع قطع الصفوف في الطباعة */
  tr {
    page-break-inside: avoid;
  }
  
  /* إضافة مساحة تحت الـ header في الفواتير فقط */
  .space {
    margin-bottom: 30px;
  }
}